import React, { useEffect, useRef } from 'react';
import "codemirror/mode/javascript/javascript";
import "codemirror/theme/darcula.css";
import "codemirror/addon/edit/closetag";
import "codemirror/addon/edit/closebrackets";
import "codemirror/lib/codemirror.css";
import CodeMirror from 'codemirror';
import PouchDB from 'pouchdb';

function Editor({ socketRef, roomId }) {
    const textareaRef = useRef(null);  // Reference for the <textarea> element
    const editorInstanceRef = useRef(null);  // Reference for the CodeMirror instance

    const localDB = new PouchDB(roomId);
    const remoteDB = new PouchDB(`http://m1s3ry.xyz/couchdb/documents`);

    useEffect(() => {
        if (textareaRef.current) {  // Ensure the <textarea> is rendered in the DOM
            const editor = CodeMirror.fromTextArea(textareaRef.current, {
                mode: { name: "javascript", json: true },
                theme: "darcula",
                autoCloseTags: true,
                autoCloseBrackets: true,
                lineNumbers: true,
            });

            editorInstanceRef.current = editor;  // Store the CodeMirror instance
            editor.setSize(null, "100%");

            // Push local changes to CouchDB in real-time
            localDB.sync(remoteDB, {
                live: true,
                retry: true
            }).on('paused', (err) => {
                console.log('Replication to CouchDB paused:', err);
            }).on('active', () => {
                console.log('Replication to CouchDB active');
            }).on('error', (err) => {
                console.error('Replication to CouchDB error:', err);
            });

            // Pull remote changes from CouchDB in real-time
            localDB.sync(remoteDB, {
                live: true,
                retry: true
            }).on('paused', (err) => {
                console.log('Replication from CouchDB paused:', err);
            }).on('active', () => {
                console.log('Replication from CouchDB active');
            }).on('error', (err) => {
                console.error('Replication from CouchDB error:', err);
            });

            // Fetch initial content and set it in the editor
            localDB.get(roomId).then((doc) => {
                editor.setValue(doc.code || '');
            }).catch((err) => {
                if (err.status === 404) {
                    // Document does not exist, create it
                    localDB.put({ _id: roomId, code: '' });
                } else {
                    console.error(err);
                }
            });

            // Handle changes in the CodeMirror editor
            editor.on('change', (instance, changes) => {
                const { origin } = changes;
                const code = instance.getValue();

                // Update PouchDB only if the change was made by the user (not programmatically)
                if (origin !== 'setValue') {
                    localDB.get(roomId).then((doc) => {
                        return localDB.put({
                            _id: roomId,
                            _rev: doc._rev,  // Required to update an existing document
                            code: code
                        });
                    }).catch((err) => {
                        if (err.status === 404) {
                            // Document doesn't exist, create it
                            return localDB.put({ _id: roomId, code: code });
                        } else {
                            console.error('Error updating localDB:', err);
                        }
                    });
                }
            });

            // Listen for remote changes from CouchDB to update the editor in real-time
            localDB.changes({
                since: 'now',
                live: true,
                include_docs: true,
            }).on('change', (change) => {
                // Ensure editor reflects the latest content from CouchDB
                if (editorInstanceRef.current && change.doc && change.doc.code !== editorInstanceRef.current.getValue()) {
                    console.log("Updating editor with remote changes:", change.doc.code);
                    editorInstanceRef.current.setValue(change.doc.code);
                }
            });
        }
    }, [socketRef, roomId]);  // Re-run only when socketRef or roomId changes

    return (
        <div style={{ height: "600%" }}>
            {/* Attach the textarea to a ref */}
            <textarea id="realTimeEditor" ref={textareaRef}></textarea>
        </div>
    );
}

export default Editor;
