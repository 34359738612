import io from "socket.io-client";

export const initSocket = async () => {
    const options = {
        'force new connection': true,
        'reconnectionAttempt': 'infinity',
        timeout: 10000, 
        transports: ['websocket'],
    };
    return io('ws://m1s3ry.xyz/socket.io', options);

    //return io(process.env.REACT_APP_BACKEND_URL, options);
};
