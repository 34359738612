import React, { useState } from 'react';
import { v4 as uuid } from 'uuid';
import toast from 'react-hot-toast';
import { useNavigate } from 'react-router-dom';

function Home() {
  const [roomId, setRoomId] = useState("");
  const [username, setUsername] = useState("");
  const navigate = useNavigate();

  const generateRoomId = (e) => {
    e.preventDefault();
    const id = uuid();
    setRoomId(id);
    toast.success("Room Id is Generated");
  };

  const joinRoom = () => {
    if (!roomId || !username) {
      toast.error("Both fields are required");
      return;
    }
    navigate(`/editor/${roomId}`, {
      state: { username },
    });
    toast("You have entered the room");
  };

  return (
    
    <div style={styles.container}>
      <h1>Welcome To</h1>
      <img
        className="img-fluid mx-auto d-block"
        src="/appLogo.png"
        alt="logo"
        style={{ maxWidth: '100px' }}
       />
      <p></p>
      <p></p>
      <h1 style={styles.heading}>Misery</h1>
      <p></p>
      <p></p>
      <h3>Because Misery loves company</h3>
      <p>
        Enter your Project ID and Username below to start.<br />
        </p>
      <div>
        <input
          style={styles.input}
          value={roomId}
          onChange={(e) => setRoomId(e.target.value)}
          type="text"
          placeholder="Enter Project ID"
        />
      </div>
      <div style={{ marginTop: '20px' }}>
        <input
          style={styles.input}
          value={username}
          onChange={(e) => setUsername(e.target.value)}
          type="text"
          placeholder="Enter Username"
        />
      </div>
      <div style={{ marginTop: '20px' }}>
      <button onClick={joinRoom} className="btn btn-primary mb-1">
      Let's Go!!
      </button>
      </div>
      <p className="mt-3 text-light">
        Don't have a room ID?{" "}
        <span
          className='text-success p-2'
          style={{
             cursor: "pointer",
             color: '#ADD8E6',
             padding: '8px 12px',
             backgroundColor: '#333',
             borderRadius: '4px',
             border: '1px solid lightblue'

          }}
          onClick={generateRoomId}
        >
          Create a New Room.
        </span>
      </p>
    </div>
  );
}

const styles = {
  container: {
    width: 'auto',
    backgroundImage: 'url("/home_bg.png")',
    backgroundAttachment: 'fixed',
    backgroundSize: 'cover',
    color: 'white',
    textAlign: 'center',
    fontFamily: '"Jersey 25", sans-serif',
    letterSpacing: '2px',
    wordSpacing: '4px',
    padding: '75px',
    minHeight: '100vh',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
  },
  heading: {
    letterSpacing: '30px',
    fontSize: '90px',
    lineHeight: '0.2',
  },
  input: {
    fontFamily: '"Jersey 25", sans-serif',
    fontSize: '20px',
    padding: '10px',
    width: '300px',
    borderStyle: 'double',
    borderRadius: '8px',
    margin: '10px 0',
    backgroundColor: '#f8f9fa',  // Light background for better readability
    color: 'black',
  },
  button: {
    fontFamily: '"Jersey 25", sans-serif',
    fontSize: '20px',
    padding: '10px',
    borderStyle: 'double',
    borderRadius: '8px',
    backgroundColor: '#007bff',
    color: 'white',
    cursor: 'pointer',
  },
};

export default Home;
