import React, { useEffect, useState, useRef } from 'react';
import Client from './Client';
import Editor from './Editor';
import {initSocket} from "../socket";
import { useLocation, useParams, useNavigate, Navigate } from 'react-router-dom';
import { toast } from 'react-hot-toast'
import io from 'socket.io-client';
function EditorPage() {
  const [clients, setClients] =useState([]);
  const socketRef = useRef(null);
  const location = useLocation();
  const { roomId } = useParams();
  const navigate = useNavigate();
  useEffect(() =>{
    const init = async () => {
      socketRef.current = await initSocket();
      socketRef.current.on('connect_error', (err) => handleError(err));
      socketRef.current.on('connect_failed', (err) => handleError(err));
      
      const handleError = (e) =>{
        console.log('Socket error =>', e);
        toast.error("Socket connection failed");
        navigate('/');

      };
      socketRef.current.emit('join',{
        roomId,
        username: location.state?.username,
      });
      socketRef.current.on('connect', () => {
        console.log('Socket connected:', socketRef.current.id);  // This should log the socket ID when connected
      });
      socketRef.current.on('joined',({clients,username,socketId}) => {
        if(username !== location.state?.username){
          toast.success(`${username} joined.`);
        }
        setClients(clients);
      });
      socketRef.current.on('disconnected', ({socketId, username}) =>{
        toast.success(`${username} left the room`);
        setClients((prev) => {
          return prev.filter(
            (client) => client.socketId != socketId);
        })
      })
    };
    init();
    return () => {
      socketRef.current.disconnect();
      socketRef.current.off('joined');
      socketRef.current.off('disconnected');
    };
  },[])

    if(!location.state){
      return <Navigate to ='/' />
    }

    const copyRoomId = async () =>{
      try {
        await navigator.clipboard.writeText(roomId);
        toast.success('roomId is copied');
      } catch(error){
        toast.error('Cannot copy roomId');
      }
    };

    const leaveRoom = () => {
      navigate("/");
    };


    return (
      <div className="container-fluid vh-100">
        <div className="row h-100">
          {/* Sidebar */}
          <div
            className="col-md-2 bg-light text-light d-flex flex-column h-100 p-3"
            style={{ boxShadow: '2px 0px 4px rgba(0,0,0,0.1)' }}
          >
            <img
              src="/appLogo.png"
              alt="logo"
              className="img-fluid mx-auto mb-3"
              style={{ maxWidth: '120px' }}
            />
           
            <hr />
            
            {/* Client list container */}
            <div className="d-flex flex-column overflow-auto mb-3">
              {clients.map((client) => (
                <div
                  key={client.socketId}
                  className="d-flex align-items-center p-2"
                  style={{
                    backgroundColor: '#000',
                    borderRadius: '4px',
                    marginBottom: '5px',
                    color: '#fff'
                  }}
                >
                  <Client username={client.username} />
                </div>
              ))}
            </div>
    
            {/* Buttons */}
            <div className="mt-auto d-flex flex-column">
              <hr />
              <button onClick={copyRoomId} className="btn btn-secondary mb-2">
                Copy Room ID
              </button>
              <button onClick={leaveRoom} className="btn btn-dark">
                Leave Room
              </button>
            </div>
          </div>
    
          {/* Editor Area */}
          <div className="col-md-10 text-light d-flex flex-column h-100 bg-secondary p-4">
            <Editor socketRef={socketRef} roomId={roomId} />
          </div>
        </div>
      </div>
    );
    
}

export default EditorPage;
